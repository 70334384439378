import React from "react";
import 'video-react/dist/video-react.css'; // Asegúrate de que este CSS no interfiera con el posicionamiento del botón
import { Skeleton, Button } from '@mui/material';
import ReactLoading from 'react-loading';
import { CloudDownload } from '@mui/icons-material'; // Importa el icono de descarga

const VideoPlayer = ({ videoUrl, showVideo, aspectRatio, loading, isTest }) => {
    if (!showVideo) return <></>

    if (loading) {
        return (
            <div style={{ width: '100%', height: '500px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReactLoading type={'bubbles'} color='#388e3c' height={'200px'} width={'200px'} />
            </div>
        );
    }

    return (
        <div style={{ width: '100%', height: '500px', position: 'relative' }}>
            <video loop={isTest} autoPlay={isTest} controls={true} style={{ width: '100%', height: '100%' }}>
                <source src={videoUrl} />
            </video>
            {videoUrl && <Button
                variant="contained"
                startIcon={<CloudDownload />}
                href={videoUrl}
                download
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    margin: '10px', // Ajusta para un mejor posicionamiento
                    zIndex: 1000, // Asegúrate de que el botón esté por encima del video
                }}
            >
                Descargar
            </Button>}
        </div>
    );
};

export default VideoPlayer;
