import React, { useState, useEffect } from "react";
import Layout from '../components/base/Layout';
import {Box, Tab, Tabs, AppBar } from '@mui/material';
import axios from 'axios';
import { Toaster, toast } from 'sonner';

import Tip from '../components/Tip'

import FileList from "../components/FileList";

import { supabase } from "../lib/supabase"; 

import CreateSlide from "../components/slides/CreateSlide";

const Slides = () => {
    const [tabValue, setTabValue] = useState(0);
    const [presentationPrompt, setPresentationPrompt] = useState('');
    const [numSlides, setNumSlides] = useState(1);
    const [titles, setTitles] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(0);
    const [loadingTitles, setLoadingTitles] = useState(false)
    const [loadingPresentation, setLoadingPresentation] = useState(false)
    const [downloadID, setDownloadID] = useState(null)


    // Function to check if the maximum number of slide creations has been reached
    const checkUsageLimit = () => {
        const usageData = JSON.parse(localStorage.getItem('presentationUsageData')) || { slideCreations: 0, maxSlideCreations: 100 }; // Adjust maxSlideCreations as necessary

        if (usageData.slideCreations >= usageData.maxSlideCreations) {
            toast('Has alcanzado el número máximo de creaciones de presentaciones permitidas.');
            return false;
        }

        usageData.slideCreations += 1;
        localStorage.setItem('presentationUsageData', JSON.stringify(usageData));

        return true;
    };

    // Function to initiate presentation generation
    const generatePresentation = async () => {
        //if (!checkUsageLimit()) return; 

        setLoadingPresentation(true); // Proceed with presentation generation

        const { data } = await supabase.auth.getUser()

        const baseURL = process.env.REACT_APP_BACKEND + '/slides/presentation';
        const queryParams = new URLSearchParams({
            prompt: presentationPrompt,
            titles: JSON.stringify(titles),
            template_id: selectedTemplate,
            user_id: data.user.id
        });

        const url = `${baseURL}?${queryParams}`;
        window.open(url, '_blank');

        recordUsage();
    };

    // Function to increment and record the usage
    const recordUsage = () => {
        const usageData = JSON.parse(localStorage.getItem('usageData')) || { presentations: 0 };
        usageData.presentations += 1;
        localStorage.setItem('usageData', JSON.stringify(usageData));

    };

    const generateTitles = () => {
        setLoadingTitles(true)
        setTitles([])

        axios.post(process.env.REACT_APP_BACKEND + '/slides/titles', {
            prompt: presentationPrompt,
            numSlides: numSlides
        })
            .then(response => {
                setTitles(response.data)
                setLoadingTitles(false)
            })
            .catch(error => {
                // Handle the error here
            });
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Layout title='Crear Presentación'>
                <Toaster position="bottom-left" />
                <Tip title="Modulo de creación de presentaciones con IA." />

                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="simple tabs example">
                        <Tab label="Crear" />
                        <Tab label="Presentaciones guardadas" />
                    </Tabs>
                </AppBar>

                {tabValue === 0 && (
                    <CreateSlide
                        presentationPrompt={presentationPrompt}
                        setPresentationPrompt={setPresentationPrompt}
                        numSlides={numSlides}
                        setNumSlides={setNumSlides}
                        titles={titles}
                        setTitles={setTitles}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        generateTitles={generateTitles}
                        loadingTitles={loadingTitles}
                        generatePresentation={generatePresentation}
                    />
                )}

                {tabValue === 1 && (
                    <Box sx={{ p: 4 }}>
                        <FileList fileType='slides' />
                    </Box>
                )}
            </Layout>
        </>
    );
};

export default Slides;
