import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import * as Yup from 'yup';

import { Button, Grid, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom' 

import { Layout } from './Layout';
import { supabase } from '../../lib/supabase'

export const Signup = () => {
  const navigate = useNavigate();

  const validate = Yup.object({
    firstName: Yup.string()
      .min(3, 'Debe tener 3 caracteres como mínimo')
      .max(15, 'Debe tener 15 caracteres o menos')
      .required('Requerido'),
    lastName: Yup.string()
      .max(20, 'Debe tener 20 caracteres o menos')
      .required('Requerido'),
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('El correo electrónico es obligatorio'),
    password: Yup.string()
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
      .required('La contraseña es obligatoria'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'La contraseña debe coincidir')
      .required('Confirmar contraseña es obligatorio'),
  });

  const handleSubmit = async (values) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: values.email,
        password: values.password,
      });

      if (error) {
        console.error(error.message);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (

    <>
      <Layout title={'Registrarse'}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={validate}
          onSubmit={values => {
            console.log(values)
            handleSubmit(values)
          }}
        >
          {formik => (
            <>
              <Typography variant="subtitle1" style={{ color: "grey", textAlign: "center" }}>
                ¿Tienes cuenta? <Link onClick={(e) => navigate("/auth/login")}>Iniciar sesión</Link>
              </Typography>

              <Form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField placeholder="Nombre" name="firstName" type="text" fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField placeholder="Apellido" name="lastName" type="text" fullWidth />
                  </Grid>
                </Grid>

                <TextField placeholder="Correo electrónico" name="email" type="email" fullWidth />
                <TextField placeholder="Contraseña" name="password" type="password" fullWidth />
                <TextField placeholder="Confirmar contraseña" name="confirmPassword" type="password" fullWidth />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="medium"
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    borderRadius: '28px',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  }}>
                  Registrarse
                </Button>
              </Form>
            </>
          )}
        </Formik>
      </Layout>
    </>

  )
}