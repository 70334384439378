import './Chat.css'
import React, { useEffect, useState, useRef } from "react";

import {
    Button,
    TextField,
    Box,
    Typography,
    Card,
    CardContent,
    AppBar,
    Toolbar,
    IconButton,

} from '@mui/material'

import { Send, Menu } from '@mui/icons-material';

import { BsFillChatDotsFill } from 'react-icons/bs'
import { MdMicNone, MdOutlineMic } from 'react-icons/md'
import useSpeechToText from 'react-hook-speech-to-text';

import Message from './Message'

//import ChatHeader from './ChatHeader'


const Chat = ({ handleSubmit, message, setMessage, chatHistory, setChatHistory, loading, setLoading }) => {
    const {
        isRecording,
        results,
        setResults,
        startSpeechToText,
        stopSpeechToText,
    } = useSpeechToText({
        continuous: true,
        timeout: 10000,
    });

    const messagesContainerRef = useRef(null);

    useEffect(() => {
        setMessage(results.join(' '));
        console.log(results);
    }, [results]);


    useEffect(() => {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }, [chatHistory]);


    return (

        <Card>
            <CardContent>

                {/* <ChatHeader /> */}
                <Typography variant="h5"><BsFillChatDotsFill /> Chat</Typography>

                <Box ref={messagesContainerRef} sx={{ minHeight: 600, maxHeight: 600, overflow: 'auto', 'overflow-x': 'hidden', p: 2 }}>
                    {chatHistory.map((item, index) => (
                        <Message key={index} from={item.from} message={item.content} />
                    ))}
                    {loading && <Message from={'assistant'} message={<>Cargando</>} />}
                </Box>


                <TextField
                    value={message}
                    fullWidth
                    placeholder="¿De que trata este documento?"
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            isRecording ?
                                <div className="chatMic enableChat" onClick={startSpeechToText}><MdMicNone  size={'1.6em'} /></div> :
                                <div className="chatMic disableChat" onClick={stopSpeechToText}><MdOutlineMic size={'1.6em'} /></div>
                        ),
                        endAdornment: (
                            <Button disabled={loading} variant="contained" type="submit">
                                Enviar <Send style={{ marginLeft: '10px' }} />
                            </Button>
                        ),
                    }}
                />
            </CardContent>
        </Card>

    )

}

export default Chat;