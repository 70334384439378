import React, { useState } from 'react';
import {
  Button,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';

import { FileUpload } from '@mui/icons-material';
import { BiLink } from 'react-icons/bi';

import DropFile from '../DropFile';
import ItemList from './ItemList';

const Docs = ({ handleFileChange, files, setFiles, links, setLinks }) => {
  const [value, setValue] = useState(0);
  const [link, setLink] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isValidLink = (link) => {
    return link.startsWith('http');
  };

  const handleAddLink = (event) => {
    event.preventDefault();
    if (!link || !isValidLink(link)) return;
    const updatedLinks = [...links, link];
    setLinks(updatedLinks);
    setLink('');
  };

  return (
    <Card sx={{ minHeight: 730 }}>
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="document and link tabs">
            <Tab label="Documentos" />
            <Tab label="Enlaces" />
          </Tabs>
        </Box>
        <Box sx={{ p: 3, display: value === 0 ? 'block' : 'none' }}>
          <DropFile
            handleChange={handleFileChange}
            acceptedFiles={[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
          />
                <ItemList items={files.map(file => file.name )} setItems={setFiles} type="file" />
            </Box>

        <Box sx={{ p: 3, display: value === 1 ? 'block' : 'none' }}>
          <TextField
            onSubmit={(e) => {
              e.preventDefault();
            }}
            value={link}
            placeholder="Inserta un enlace"
            onChange={(e) => setLink(e.target.value)}
            fullWidth
            sx={{
              mb: 2,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiLink />
                </InputAdornment>
              ),
              endAdornment: (
                <Button onClick={(event) => handleAddLink(event)} variant="contained">
                  <FileUpload />
                </Button>
              ),
            }}
          />
          <ItemList items={links} setItems={setLinks} type="link" />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Docs;
