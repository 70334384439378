import { BiMoviePlay } from "react-icons/bi"; 
import React, { useState, useRef, useEffect } from "react";
import { Chip, Grid} from '@mui/material';

import AssetThumbnail from "./AssetThumbnail";

const CustomInputStyle = {
    color: 'white',
    width: '100%',
    marginTop: '10px',
    padding: '14px',
    borderRadius: '10px',
    border: 'none',
    fontSize: '16px',
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    background: '#252525',
    resize: 'none',
    overflow: 'hidden',
    '&:active': {
        border: 'none',
    },
};

const Clip = ({ index, clips, setClips }) => {
    const myForm = useRef(null);

    const handleTextChange = (e) => {
        setClips((prevClips) => {
            const newClips = [...prevClips];
            newClips[index].text = e.target.value;
            return newClips;
        });
    };

    const handleVideoChange = (videoIndex) => {
        setClips((prevClips) => {
            const newClips = [...prevClips];
            newClips[index].selectedVideo = videoIndex;
            return newClips;
        });
    };

    useEffect(() => {
        myForm.current.addEventListener('input', () => {
            myForm.current.style.height = 'auto';
            myForm.current.style.height = `${myForm.current.scrollHeight}px`;
        });
    }, [clips]);

    return (
        <>
        <Chip icon={<BiMoviePlay />} sx={{ marginTop: 3 }}  label={`Clip ${index + 1}`} />

        <textarea
            ref={myForm}
            value={clips[index].text}
            onChange={(e) => handleTextChange(e)}
            rows={5}
            placeholder="Escribe tu guión aquí"
            style={{ ...CustomInputStyle }}
            maxLength={200}
        />
    
        <AssetThumbnail clip={clips[index]} handleVideoChange={handleVideoChange}/>

        </>
    );
};

export default Clip;
