import React, { useEffect } from "react";

import { IconButton, Toolbar, AppBar, Typography, Hidden } from "@mui/material";
import { Menu } from '@mui/icons-material';
import { useAuth } from "../../contexts/authContext";
import { supabase } from "../../lib/supabase";


const Navbar = ({ title, open, setOpen }) => {
    const { user } = useAuth();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    // const getProfile = async () => {
    //     console.log(user)

    //      // fetch profile from user.id
    //      const { data, error } = await supabase
    //         .from('users')
    //         .select()
    //         .eq('id', user.id)
 
    //      console.log(data)
    
    // }

    // useEffect(() => {
    //     getProfile()
    // }, [])


    return (<AppBar position="fixed">
        <Toolbar>
            <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                >
                    <Menu />
                </IconButton>
            </Hidden>
            <Typography variant="h6" noWrap component="div">
                {title}
            </Typography>

        </Toolbar>
    </AppBar>)
}

export default Navbar;