import React, { useState, useEffect } from 'react';

import { Button, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { BsFilePptFill, BsFillCameraVideoFill, BsFillFileEarmarkTextFill } from 'react-icons/bs';

import { supabase } from '../lib/supabase';

const getFileIcon = (fileName) => {
    if (fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
        return <BsFilePptFill />;
    } else if (fileName.endsWith('.mp4') || fileName.endsWith('.avi')) {
        return <BsFillCameraVideoFill />;
    } else {
        return <BsFillFileEarmarkTextFill />;
    }
};

const FileList = ({ fileType }) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        const { data: { user } } = await supabase.auth.getUser()

        let { data: files, error } = await supabase
            .storage
            .from(fileType)
            .list(`${user.id}`, { limit: 1000, offset: 0 });

        if (error) console.log('Error fetching files', error);
        else setFiles(files);
        console.log(files)
    };

    const downloadFile = async (file) => {
        const { data: { user } } = await supabase.auth.getUser()

        const { data, error } = await supabase
            .storage
            .from(fileType)
            .download(`${user.id}/${encodeURIComponent(file)}`);

        if (error) {
            console.log('Error downloading file', error);
            return;
        }

        // Create a URL for the blob
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <List>
            {files.map((file, index) => (
                <ListItem key={index} button onClick={() => downloadFile(file.name)}>
                    <ListItemIcon>
                        {getFileIcon(file.name)}
                    </ListItemIcon>
                    <ListItemText primary={file.name.split('___')[0]} />
                    <ListItemText secondary={new Date(file.created_at).toLocaleDateString()} />
                </ListItem>
            ))}
        </List>
    );
};

export default FileList;
