import React from "react";
import { Card, Button, Skeleton, Box } from '@mui/material';

import Step from '../Step';

import Input from './Input'
import TitleList from './TitleList';
import TemplateSelector from "./TemplateSelector";
import TemplateCard from './TemplateCard';

import { BiSolidMagicWand } from 'react-icons/bi';

const CreateSlide = ({
    presentationPrompt,
    setPresentationPrompt,
    numSlides,
    setNumSlides,
    titles,
    setTitles,
    selectedTemplate,
    setSelectedTemplate,
    generateTitles,
    loadingTitles,
    generatePresentation
  }) => {
    return (
      <Box sx={{ p: 4 }}>
        <Card sx={{ p: 4 }}>
          <Step index={1} description={'Describe de qué tratará tu presentación.'} />
          <Input
            presentationPrompt={presentationPrompt}
            setPresentationPrompt={setPresentationPrompt}
            numSlides={numSlides}
            setNumSlides={setNumSlides}
            generateTitles={generateTitles}
          />
        </Card>
  
        {(titles.length > 0 || loadingTitles) && <Card sx={{ p: 4, mt: 2 }}>
          {titles.length > 0 && <Step index={2} description={'Ordena las diapositivas arrastrándolas o modifica su título.'} />}
          {loadingTitles && <Box>
            {Array(numSlides).fill().map((_, index) => (
              <Skeleton key={index} animation="wave" height="100px" />
            ))}
          </Box>}
          {titles.length > 0 && <TitleList titles={titles} setTitles={setTitles} />}
        </Card>}
  
        {titles.length > 0 && <Card sx={{ p: 4, mt: 2 }}>
          <Step index={3} description={'¡Selecciona una plantilla!'} />
          <TemplateSelector
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
          <Button sx={{ mt: 5, width: '100%', height: '50px', borderRadius: '10px', fontSize: '18px' }} disabled={!selectedTemplate} variant="contained" color="primary" onClick={generatePresentation}>
            Crear Presentación <BiSolidMagicWand style={{ paddingLeft: '10px', fontSize: '40px' }} />
          </Button>
        </Card>}
      </Box>
    );
  };
  
  export default CreateSlide;