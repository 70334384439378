import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar } from '@mui/material';


const Tip = ({ title, subtitle }) => (
    <Card sx={{ mb: 2}}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar alt="Bot" src="\assets\image\bot.png"/>
          </Grid>
          <Grid item xs>
            <Typography variant="h6">
                <div dangerouslySetInnerHTML={{
                    __html: title
                }}>
                </div>
            </Typography>
            <Typography variant="body2"> 
                <div dangerouslySetInnerHTML={{
                    __html: subtitle
                }}>
                </div></Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
);

export default Tip;