import {Typography } from '@mui/material';

import { BiSolidMagicWand } from 'react-icons/bi';

const Step = ({ index, description }) => {
    return <Typography mt={2} mb={2}>
        <BiSolidMagicWand style={{ fontSize: 25 }} /> Paso {index}: {description}.
    </Typography>

}

export default Step