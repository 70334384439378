import React, { useEffect } from 'react';

const SupportChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/652e2a75a84dd54dc481f84e/1hcu5bmlr';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.head.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default SupportChat;