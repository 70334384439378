
import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../contexts/authContext'

export function PrivateRoute({ children }) {
    const { user } = useAuth()
    if (!user) {
        return <Navigate to="/auth/login" replace />;
    }    

    return children;
}
