import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { Grid, LinearProgress, Typography, Box, Tab, Tabs, AppBar } from '@mui/material';

import Layout from '../components/base/Layout';

import Settings from '../components/video/Settings'
import ScriptEditor from '../components/video/ScriptEditor'
import VideoPlayer from '../components/video/VideoPlayer'
import elevenlabs from '../lib/elevenlabs'

import { FFmpeg } from '@ffmpeg/ffmpeg';

import { fetchFile } from '@ffmpeg/util'

import FileList from "../components/FileList";
import Tip from '../components/Tip'

import { supabase } from "../lib/supabase";

const Video = () => {
    const [clips, setClips] = useState([]);
    const [script, setScript] = useState("");
    const [voiceId, setVoiceId] = useState("amazonpoly|Mia");
    const [voiceProvider, setVoiceProvider] = useState("amazonpoly");
    const [aspectRatio, setAspectRatio] = useState("16:9");
    const [backgroundMusic, setBackgroundMusic] = useState("chill");
    const [showVideo, setShowVideo] = useState(true)
    const [isTest, setIsTest] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currentTask, setCurrentTask] = useState(null)
    const [loadingPercentage, setLoadingPercentage] = useState(0)

    const [tabValue, setTabValue] = useState(0);

    const ffmpegRef = useRef(new FFmpeg());
    const videoRef = useRef(null);

    const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
 
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    
    const loadFFmpeg = async () => {
        await ffmpegRef.current.load();
        
        const ffmpeg = ffmpegRef.current;

        ffmpeg.on('log', ({ message }) => {
            console.log('FFMPEG:', message);
        });

        setFfmpegLoaded(true);
    };

    const writeVideoFiles = async (ffmpeg, clips, splashScreenFilename) => {
        let fileListContent = ``;

        // const splashScreenMedia = `splashscreen.png`;
        // await ffmpeg.exec(['-i', splashScreenFilename, '-c:v', 'copy', splashScreenMedia]);

        // fileListContent += `file '${splashScreenFilename}'\nduration 5\n`;


        for (const [index, clip] of clips.entries()) {
            const videoFilename = `input${index}.mp4`;
            let videoUrl;

            if (clip.selectedVideo.file instanceof File) {
                await ffmpeg.writeFile(videoFilename, await fetchFile(clip.selectedVideo.file));
            } else {
                videoUrl = clip.videos[clip.selectedVideo].video_files.find(file => file.quality === 'sd').link;
                await ffmpeg.writeFile(videoFilename, await fetchFile(videoUrl));
            }

            // Crear una versión sin audio del video.
            const noAudioFilename = `noAudio${index}.mp4`;
            await ffmpeg.exec(['-i', videoFilename, '-c:v', 'copy', '-an', noAudioFilename]);

            // Agregar el archivo sin audio a la lista para la concatenación.
            fileListContent += `file '${noAudioFilename}'\n`;

        }

        return fileListContent;
    };

    const convertVideosForConcatenation = async (ffmpeg, inputFilename, outputFilename) => {
        await ffmpeg.exec(['-i', inputFilename, '-c:v', 'libx264', '-crf', '35', '-preset', 'ultrafast', '-s', '480x270', '-an', outputFilename]);
    };
    
    const writeFileListForConcatenation = async (ffmpeg, fileListContent) => {
        const fileList = 'filelist.txt';
        await ffmpeg.writeFile(fileList, fileListContent);
        return fileList;
    };

    const fetchAndWriteSplashScreen = async (ffmpeg, mediaName) => {
        const filename = `${mediaName}`;
        const mediaURL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/splashscreen/${mediaName}`;
        const mediaData = await fetchFile(mediaURL);
        await ffmpeg.writeFile(filename, mediaData);
        return filename;
    };

    const fetchAndWriteTTS = async (ffmpeg, script) => {
        let url;

        const [service, voice] = voiceId.split('|');
        
        if (service === 'elevenlabs') {
            url = await elevenlabs(script, voice);
        } else if (service === 'amazonpoly') {
            url = `https://api.streamelements.com/kappa/v2/speech?voice=${voice}&text=${encodeURIComponent(script)}`;
        }

        const filename = 'tts.mp3';
        const audio = await fetchFile(url);
        await ffmpeg.writeFile(filename, audio);

        return filename;
    };

    const fetchAndWriteBackgroundMusic = async (ffmpeg, volume = 0.5) => {
        const filename = 'backgroundMusic.mp3';
        const audio = await fetchFile(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/music/${backgroundMusic}.mp3`);
        
        await ffmpeg.writeFile(filename, audio);
        return filename;
    };


    const renderVideo = async (ffmpeg, fileList, ttsFilename, backgroundMusicFilename, splashScreenFilename) => {
        await ffmpeg.exec([
            '-f', 'concat', '-safe', '0', '-i', fileList, // Entrada de videos concatenados
            '-i', ttsFilename, // Entrada de TTS
            '-i', backgroundMusicFilename, // Entrada de música de fondo
            '-filter_complex', '[1:a]volume=1[a1];[2:a]volume=0.2[a2];[a1][a2]amix=inputs=2:duration=shortest', // Mezcla TTS y música de fondo con ajuste de volumen
            '-c:v', 'copy', // Copia el video tal cual
            '-c:a', 'aac', // Codifica el audio mezclado a AAC
            '-strict', 'experimental', // Permite características experimentales, si es necesario
            '-shortest', // Termina el archivo de salida cuando el stream más corto termine
            'output.mp4'
        ]);
    };

    const generateVideo = async () => {        
        try {
            setLoading(true);
            setCurrentTask('Inicializando la generación del video');
            setLoadingPercentage(1);

            const ffmpeg = ffmpegRef.current;

            setLoadingPercentage(60);
            setCurrentTask('Añadiendo intro...');
            const splashScreenFilename = await fetchAndWriteSplashScreen(ffmpeg, 'remax.png');
            
            setLoadingPercentage(10);
            setCurrentTask('Preparando videos...');
            const fileListContent = await writeVideoFiles(ffmpeg, clips, splashScreenFilename);
            
            setLoadingPercentage(40);
            setCurrentTask('Organizando contenido...');
            const fileList = await writeFileListForConcatenation(ffmpeg, fileListContent);

            setLoadingPercentage(60);
            setCurrentTask('Generando voz sintética...');
            const ttsFilename = await fetchAndWriteTTS(ffmpeg, script);

            setLoadingPercentage(60);
            setCurrentTask('Agregando música de fondo...');
            const backgroundMusicFilename = await fetchAndWriteBackgroundMusic(ffmpeg);

            setLoadingPercentage(80);
            setCurrentTask('Finalizando video...');

            await renderVideo(ffmpeg, fileList, ttsFilename, backgroundMusicFilename, splashScreenFilename);
            
            let videoData = await ffmpeg.readFile('output.mp4');
            const videoBlob = new Blob([videoData.buffer], { type: 'video/mp4' });
            const videoObjectUrl = URL.createObjectURL(videoBlob);

            setVideoUrl(videoObjectUrl);
            setLoading(false);
            
            console.log("Video listo en URL:", videoObjectUrl);

            setLoadingPercentage(100);

            let { data } = await supabase.auth.getUser()

            const { data: uploadData, error: uploadError } = await supabase
                .storage
                .from('videos')
                .upload(`${data.user.id}/video-${Date.now()}.mp4`, videoBlob, {
                    contentType: 'video/mp4',
                    upsert: true,
                });

            if (uploadError) {
                throw uploadError;
            }

            console.log('Video subido con éxito a Supabase Storage:', uploadData);

        } catch (error) {
            // Handle the error gracefully
            console.error("Error during video generation:", error);
            setLoading(false);
            // You might want to set an error state or display a user-friendly message
        }
    };

    useEffect(() => {
        loadFFmpeg();
    }, []);

    useEffect(() => {
        console.log("clips changed", clips);
        console.log("script", script)
    }, [clips]);

    return (
        <>
            <Layout title='Crear Video'>

                <Tip title="Creacion de videos con IA." subtitle='En este modulo podras crear videos sobre algun tema, puedes cargar PDFs, Archivos de word y documentos de texto' />


                <AppBar position="static">
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="simple tabs example">
                        <Tab label="Crear" />
                        <Tab label="Videos guardados" />
                    </Tabs>
                </AppBar>


                {tabValue === 0 && (

                    <Grid container spacing={2}>
                        <Grid item lg={showVideo ? '6' : '12'} md={showVideo ? '6' : '12'} sm={12} xs={12}>
                            <ScriptEditor
                                clips={clips}
                                setClips={setClips}
                                script={script}
                                setScript={setScript}
                            />

                        </Grid>
                        {(showVideo || loading) && <Grid item lg={6} md={6} sm={12} xs={12}>


                            <VideoPlayer
                                showVideo={showVideo}
                                videoUrl={videoUrl}
                                aspectRatio={aspectRatio}
                                loading={loading}
                                isTest={isTest}
                                key={videoUrl}
                            />

                            {loadingPercentage != 0 && loadingPercentage != 100 && (
                                <>
                                    <LinearProgress sx={{ height: '20px' }} variant="determinate" value={loadingPercentage} />
                                    {currentTask && <Typography variant='h6'>{currentTask}</Typography>}
                                </>
                            )}


                            <Settings
                                script={script}
                                voiceId={voiceId}
                                setVoiceId={setVoiceId}
                                backgroundMusic={backgroundMusic}
                                setBackgroundMusic={setBackgroundMusic}
                                aspectRatio={aspectRatio}
                                setAspectRatio={setAspectRatio}
                                voiceProvider={voiceProvider}
                                setVoiceProvider={setVoiceProvider}
                                generateVideo={generateVideo}
                                loading={loading}
                                ffmpegLoaded={ffmpegLoaded}
                            />

                        </Grid>}
                    </Grid>
                )}
                {tabValue === 1 && (
                    <Box sx={{ p: 4 }}>
                        <FileList fileType='videos' />
                    </Box>
                )}


            </Layout>
        </>
    );
};

export default Video;
