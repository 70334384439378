import axios from 'axios';

const elevenlabs = async (text, voiceId) => {
  const apiRequestOptions = {
    method: 'POST',
    url: `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`,
    headers: {
      accept: 'audio/mpeg',
      'content-type': 'application/json',
      'xi-api-key': process.env.REACT_APP_ELEVEN_LABS_API_KEY,
    },
    data: {
      text: text,
      model_id: "eleven_multilingual_v2"
    },
    responseType: 'arraybuffer', 
  };

  try {

    const apiResponse = await axios.request(apiRequestOptions);
    
    const audioBlob = new Blob([apiResponse.data], { type: 'audio/mpeg' });
    
    const blobUrl = URL.createObjectURL(audioBlob);
    
    return blobUrl;
  } catch (e) {
    //todo: usar voz de google
  }
};

export default elevenlabs;
