import React from 'react';
import { Box } from '@mui/material';

import { DropzoneArea } from 'material-ui-dropzone'
import { FileIcon } from 'react-file-icon';
import { AttachFile } from '@mui/icons-material';

const DropFile = ({ acceptedFiles, handleChange }) => {

    const handlePreviewIcon = (fileObject, classes) => {
        const { type, name } = fileObject.file;

        const commonIconProps = {
            width: 60,
            labelUppercase: true,
            glyphColor: "rgba(255,255,255,0.4)",
        };

        let iconComponent;

        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                iconComponent = (
                    <FileIcon
                        color="#2C5898"
                        labelColor="#2C5898"
                        type="document"
                        extension="doc"
                        {...commonIconProps}
                    />
                );
                break;
            case "application/pdf":
                iconComponent = (
                    <FileIcon
                        color="#e5252a"
                        labelColor="#e5252a"
                        type="document"
                        extension="PDF"
                        {...commonIconProps}
                    />
                );
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                iconComponent = (
                    <FileIcon
                        color="#1A754C"
                        labelColor="#1A754C"
                        labelUppercase
                        type="spreadsheet"
                        glyphColor="rgba(255,255,255,0.4)"
                        extension="EXEL"
                    />
                );
                break;

            default:
                iconComponent = <AttachFile className={classes.image} />;
        }

        return (
            <Box width={60} sx={{
                textAlign: '-webkit-center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                'max-height': '103px'
            }}>
                {iconComponent}
            </Box>
        );
    };

    const getFileLimitExceedMessage = (filesLimit) => {
        return `La cantidad máxima de archivos que se pueden subir a la vez son ${filesLimit}`;
    }

    const getFileRemovedMessage = (fileName) => {
        return `El archivo ${fileName} se ha quitado.`;
    }

    const getFileAddedMessage = (fileName) => {
        return `El archivo ${fileName} se ha agregado correctamente.`;
    }

    return (
        <DropzoneArea
            onChange={(e) => handleChange(e)}
            showAlerts={false}
            getPreviewIcon={handlePreviewIcon}
            getFileLimitExceedMessage={getFileLimitExceedMessage}
            getFileRemovedMessage={getFileRemovedMessage}
            getFileAddedMessage={getFileAddedMessage}
            dropzoneText={'Arrastra documentos aquí o haz clic'}
            filesLimit={8}
            maxFileSize={30000000}
            showPreviews={false}
            showPreviewsInDropzone={false}
            showFileNamesInPreview={true}
            dropzoneClass='dropzone'
            dropzoneParagraphClass='dropzone-text'
            acceptedFiles={acceptedFiles}
        />
    );
};

export default DropFile;
