import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, FormLabel, Button, Box, Card, Grid } from '@mui/material';
import { IoSparkles } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import Clip from './Clip';
import { Add } from '@mui/icons-material';
import { pexels } from '../../lib/pexels';

import ItemList from '../docs/ItemList';

const ScriptEditor = ({ clips, setClips, script, setScript, testClip }) => {
    const { search } = useLocation();
    const [prompt, setPrompt] = useState('');
    const [prepromt, setPrepromt] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    const [files, setFiles] = useState([]);
    const [links, setLinks] = useState([]);
    const [link, setLink] = useState('');

    const handleFileUpload = async (event) => {

        const files = Array.from(event.target.files);

        try {
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append('files', file);
            });

            links.forEach((link, index) => {
                formData.append('urls', link);
            });

            let response = await fetch(`${process.env.REACT_APP_BACKEND}/docs/parse`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                return
                // Si la respuesta no es exitosa (status code no está en el rango 200-299)
                // throw new Error(`Error en la solicitud: ${response.status} - ${response.statusText}`);
            }


            const fileNames = files.map(file => file.name);
            setFiles(prevFiles => [...prevFiles, ...fileNames]);

            const result = await response.json();
            setPrepromt(result.text + ' /////////// resume la informacion anterior aterior. ')
        } catch (error) {
            console.error('Error en la solicitud:', error);

            // Puedes manejar el error de manera adecuada, por ejemplo, lanzando una excepción
            // throw new Error('Error en la solicitud: ' + error.message);
        }
    }

    const fetchVideos = async (query) => {
        // Obtener la lista de videos desde la API Pexels
        let { videos } = await pexels.videos.search({ query, per_page: 10 });

        // Filtrar solo los videos horizontales
        const horizontalVideos = videos.filter(video => video.width > video.height);

        if (horizontalVideos.length === 0) {
            horizontalVideos.push({
                "id": 4763824,
                "width": 3840,
                "height": 2160,
                "duration": 14,
                "full_res": null,
                "tags": [],
                "url": "https://www.pexels.com/video/drone-flying-over-the-mountain-peak-4763824/",
                "image": "https://images.pexels.com/videos/4763824/4k-4k50fps-adventure-backpack-4763824.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200",
                "avg_color": null,
                "user": {
                    "id": 649765,
                    "name": "Yaroslav Shuraev",
                    "url": "https://www.pexels.com/@yaroslav-shuraev"
                },
                "video_files": [
                    {
                        "id": 954645,
                        "quality": "hd",
                        "file_type": "video/mp4",
                        "width": 3840,
                        "height": 2160,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.hd.mp4?s=20f75bfc99c6577c31e4a530dbc0776ce9ba4c27&profile_id=172&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954646,
                        "quality": "hd",
                        "file_type": "video/mp4",
                        "width": 1280,
                        "height": 720,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.hd.mp4?s=20f75bfc99c6577c31e4a530dbc0776ce9ba4c27&profile_id=174&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954647,
                        "quality": "sd",
                        "file_type": "video/mp4",
                        "width": 640,
                        "height": 360,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.sd.mp4?s=c27eecc69a27dbc4ff2b87d38afc35f1a9e7c02d&profile_id=164&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954648,
                        "quality": "hd",
                        "file_type": "video/mp4",
                        "width": 2560,
                        "height": 1440,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.hd.mp4?s=20f75bfc99c6577c31e4a530dbc0776ce9ba4c27&profile_id=170&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954649,
                        "quality": "hd",
                        "file_type": "video/mp4",
                        "width": 1920,
                        "height": 1080,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.hd.mp4?s=20f75bfc99c6577c31e4a530dbc0776ce9ba4c27&profile_id=175&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954650,
                        "quality": "sd",
                        "file_type": "video/mp4",
                        "width": 426,
                        "height": 240,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.sd.mp4?s=c27eecc69a27dbc4ff2b87d38afc35f1a9e7c02d&profile_id=139&oauth2_token_id=57447761"
                    },
                    {
                        "id": 954651,
                        "quality": "sd",
                        "file_type": "video/mp4",
                        "width": 960,
                        "height": 540,
                        "fps": 24,
                        "link": "https://player.vimeo.com/external/434045526.sd.mp4?s=c27eecc69a27dbc4ff2b87d38afc35f1a9e7c02d&profile_id=165&oauth2_token_id=57447761"
                    }
                ],
                "video_pictures": [
                    {
                        "id": 1911019,
                        "nr": 0,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-0.jpg"
                    },
                    {
                        "id": 1911020,
                        "nr": 1,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-1.jpg"
                    },
                    {
                        "id": 1911021,
                        "nr": 2,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-2.jpg"
                    },
                    {
                        "id": 1911022,
                        "nr": 3,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-3.jpg"
                    },
                    {
                        "id": 1911023,
                        "nr": 4,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-4.jpg"
                    },
                    {
                        "id": 1911024,
                        "nr": 5,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-5.jpg"
                    },
                    {
                        "id": 1911025,
                        "nr": 6,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-6.jpg"
                    },
                    {
                        "id": 1911026,
                        "nr": 7,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-7.jpg"
                    },
                    {
                        "id": 1911027,
                        "nr": 8,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-8.jpg"
                    },
                    {
                        "id": 1911028,
                        "nr": 9,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-9.jpg"
                    },
                    {
                        "id": 1911029,
                        "nr": 10,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-10.jpg"
                    },
                    {
                        "id": 1911030,
                        "nr": 11,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-11.jpg"
                    },
                    {
                        "id": 1911031,
                        "nr": 12,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-12.jpg"
                    },
                    {
                        "id": 1911032,
                        "nr": 13,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-13.jpg"
                    },
                    {
                        "id": 1911033,
                        "nr": 14,
                        "picture": "https://images.pexels.com/videos/4763824/pictures/preview-14.jpg"
                    }
                ]
            });
        }

        // Devolver la lista de videos horizontales
        return horizontalVideos;
    };

    const getSearchterm = async (search) => {
        const response = await axios.post(process.env.REACT_APP_BACKEND + '/videos/searchterm', {
            clip: search,
        });
        return response.data;
    };

    const generateClips = async (text) => {
        const texts = [];
        const maxChars = 200;

        let currentClip = '';
        const words = text.split(' ');

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (currentClip.length + word.length <= maxChars) {
                currentClip += word + ' ';
            } else {
                texts.push(currentClip.trim());
                currentClip = word + ' ';
            }
        }

        if (currentClip.trim() !== '') {
            texts.push(currentClip.trim());
        }

        const clips = await Promise.all(
            texts.map(async (text) => ({
                text: text,
                videos: await fetchVideos(await getSearchterm(text)),
                selectedVideo: 0,
            }))
        );

        return clips;
    };

    const handleGenerateScript = async () => {
        try {
            if (script.trim() !== '') {
                const shouldConfirm = window.confirm('Se reemplazará el guión actual, ¿Desea continuar?');
                if (!shouldConfirm) return;
            }
            const response = await axios.post(process.env.REACT_APP_BACKEND + '/videos/script', { prompt: prepromt + prompt });

            const clips = await generateClips(response.data);
            setClips(clips);

            setScript(response.data);
            setCharacterCount(response.data.length);
        } catch (error) {
            console.error('Error al obtener el script:', error);
        }
    };

    const handleAddLink = () => {
        if (link.trim() === '') return;
        setLinks((prevLinks) => [...prevLinks, link]);
        setLink('');
    };

    const handleAddClip = (event) => {
        setClips((prevClips) => [...prevClips, { text: '' }]);
    };

    useEffect(() => {
        const scriptFromURL = new URLSearchParams(search).get('script');
        const savedScript = localStorage.getItem('script');

        if (scriptFromURL) {
            setScript(scriptFromURL);
            return;
        }
        if (savedScript) {
            setScript(savedScript);
        }
    }, [search]);

    useEffect(() => {
        let newScript = '';
        clips.forEach((clip, index) => {
            newScript += clip.text;
        });

        setScript(newScript);
        setCharacterCount(newScript.length);
    }, [clips]);

    useEffect(() => {
        localStorage.setItem('script', script);
    }, [script]);

    return (
        <>
            <Card sx={{ p: 4 }}>
                <FormLabel>Guión</FormLabel>

                <Box display="flex" alignItems="center" justifyContent="center">
                    <TextField
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        size="small"
                        sx={{ width: '100%' }}
                        placeholder="Crea un video sobre cómo mejorar..."
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateScript}
                        startIcon={<IoSparkles />}
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        Generar
                    </Button>
                </Box>

                <ItemList items={files} setItems={setFiles} type="file" />
                <ItemList items={links} setItems={setLinks} type="link" />

                <FormLabel>Documentos</FormLabel>
   
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <input
                            style={{ background: 'rgb(41, 107, 44) ', borderRadius: '5px' }}
                            type="file"
                            multiple
                            accept=".pdf, .txt, .docx"
                            onChange={handleFileUpload}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Enlace"
                            placeholder="Añadir enlace"
                            onChange={(e) => setLink(e.target.value)}
                            value={link}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddLink}
                            startIcon={<Add />}
                            size="small"
                        ></Button>
                    </Grid>
                </Grid>

           

                <div style={{ maxHeight: '650px', overflowY: 'auto', paddingRight: '20px' }}>
                    {clips.map((clip, index) => (
                        <Clip key={index} clips={clips} setClips={setClips} index={index} testClip={testClip} />
                    ))}
                </div>

                {clips.length > 0 && (
                    <Button sx={{ mt: 1 }} variant="contained" startIcon={<Add />} onClick={handleAddClip}>
                        Agregar Escena
                    </Button>
                )}

                <div style={{ textAlign: 'right', fontSize: '12px', color: characterCount > 1999 ? 'red' : 'inherit' }}>
                    {characterCount} / 2000 caracteres
                </div>
            </Card>
        </>
    );
};

export default ScriptEditor;
