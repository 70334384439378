import React, { useState, useEffect } from 'react';
import { FormControl, TextField, Select, MenuItem, Button, FormLabel, Grid, Card } from '@mui/material';
import { IoMdMicrophone, IoMdMusicalNotes, IoMdResize } from 'react-icons/io';
import { IoSparkles } from 'react-icons/io5';
import { AiFillPlayCircle } from "react-icons/ai";

const Settings = ({
  script,
  voiceId,
  setVoiceId,
  voiceProvider,
  setVoiceProvider,
  backgroundMusic,
  setBackgroundMusic,
  aspectRatio,
  setAspectRatio,
  generateVideo,
  loading,
  ffmpegLoaded
}) => {

  return (
    <Card sx={{ p: 4, mt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl fullWidth={true}>
            <FormLabel>
              <IoMdMicrophone /> Voz
            </FormLabel>
            <Select
              value={voiceId}
              onChange={(e) => setVoiceId(e.target.value)}
              size="small"
              sx={{ mb: 2 }}
            >

              <MenuItem value="elevenlabs|XrExE9yKIg1WjnnlVkGX">Matilda</MenuItem>
              <MenuItem value="amazonpoly|Conchita">Conchita</MenuItem>
              <MenuItem value="amazonpoly|Enrique">Enrique</MenuItem>
              <MenuItem value="amazonpoly|Mia">Mia</MenuItem>
            </Select>
          </FormControl>
          {voiceId.split('|')[0] == 'amazonpoly' &&
            <audio style={{ height: '39px', padding: '1px', width: '100%' }} controls key={voiceId}>
              <source src={`https://api.streamelements.com/kappa/v2/speech?voice=${voiceId.split('|')[1]}&text=${encodeURIComponent("Hola")}`} type="audio/mpeg" />
            </audio>}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl fullWidth={true}>
            <FormLabel>
              <IoMdMusicalNotes /> Musica de fondo
            </FormLabel>
            <Select
              value={backgroundMusic}
              onChange={(e) => setBackgroundMusic(e.target.value)}
              size="small"
              sx={{ mb: 2 }}
            >
              <MenuItem value="chill">Musica tranquila</MenuItem>
              <MenuItem value="marketing">Musica para anuncio</MenuItem>
            </Select>
          </FormControl>
          <audio style={{ height: '39px', padding: '1px', width: '100%' }} controls key={backgroundMusic}>
            <source src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/music/${backgroundMusic}.mp3`} type="audio/mpeg" />
          </audio>
        </Grid>
        {/* <Grid item xs={12} md={12} lg={4}>
          <FormControl fullWidth={true}>
            <FormLabel>
              <IoMdResize /> Tamaño
            </FormLabel>
            <Select
              value={aspectRatio}
              onChange={(e) => setAspectRatio(e.target.value)}
              size="small"
              sx={{ mb: 2 }}
            >
              <MenuItem value="16:9">Horizontal</MenuItem>
              <MenuItem value="9:16">Vertical</MenuItem>
              <MenuItem value="1:1">Cuadrado</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
      <Button
        variant="contained"
        onClick={generateVideo}
        startIcon={<AiFillPlayCircle />}
        disabled={!ffmpegLoaded}
        fullWidth={true}
        sx={{
          backgroundColor: "#6200EA",
          '&:hover': {
            backgroundColor: "#3700B3",
          },
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
          mt: 2
        }}
      >
        Crear Video
      </Button>

    </Card>
  );
};

export default Settings;
