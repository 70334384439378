import React from "react";
import Layout from '../components/base/Layout';

import Tip from "../components/Tip";

const Chat = () => {
  return (
    <>
      <Layout title='CHAT IA'>

        <Tip title="Modulo de Chat con la base de datos de la empresa." subtitle='Puedes realizar consultas en base a los datos de la empresa'/>


        <iframe frameBorder='none' width={'100%'} height={600} style={{borderRadius:'20px', background:'transparent'}} src="https://wikia.totalaplicaciones.com/?token=0986b84e-b66c-4dea-9d1b-5562b26134be"></iframe>
      </Layout>
    </>
  );
};

export default Chat;
