import React from "react";
import cx from "clsx";
import { Typography, Avatar, Grid, Button } from "@mui/material";
import { FiVideo } from 'react-icons/fi'; // Importa el ícono de video

import "./Message.css";

const MsgAvatar = ({ from }) => {
  return (
    <Grid item xs={1}>
      <Avatar
        src={from === "assistant" ? "/assets/image/bot.png" : ""}
        className="msg-avatar"
      />
    </Grid>
  );
}

const Message = ({
  message,
  from,
}) => {

  const isBotMessage = from === "assistant";
  /*const isLongMessage = message?.length > 100;

  const openVideoInNewTab = () => {
    if (isBotMessage && isLongMessage) {
      const videoURL = `/video?script=${encodeURIComponent(message)}`;
      window.open(videoURL, '_blank');
    }
  }*/

  return (
    <Grid
      container
      spacing={1}
      justify={isBotMessage ? "flex-end" : "flex-start"}
      sx={{ marginTop: '20px' }}
    >
      {isBotMessage && <MsgAvatar from="assistant" />}

      <Grid item xs={11}>
        <div key={message} className={cx("msg-messageRow", { "msg-iaRow": isBotMessage, "msg-userRow": !isBotMessage })}>

          <Typography align={isBotMessage ? 'right' : 'left'}>
            {message}
          </Typography>
        </div>
        {/* {isBotMessage && isLongMessage && (
            <Button sx={{mb: 2}} color='warning' onClick={openVideoInNewTab} variant="contained">
              <FiVideo color="black" style={{marginRight: '10px'}} /> Genera video
            </Button>
          )} */}
      </Grid>

      {!isBotMessage && <MsgAvatar from="user" />}
    </Grid>
  );
}

export default Message;
