import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card, TextField, InputAdornment,Button, IconButton } from '@mui/material'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { RiSlideshowLine } from 'react-icons/ri'

import CloseIcon from '@mui/icons-material/Close';

import './TitleList.css'

const TitleList = ({ titles, setTitles }) => {

 //   const [items, setItems] = useState(titles);

    const onDragComplete = (result) => {
        if (!result.destination) return;

        const arr = [...titles];

        //Changing the position of Array element
        let removedItem = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, removedItem);

        //Updating the list
        setTitles(arr);
    };

    const addTitle = () => {
        setTitles([...titles, ""]);
    };

    const onClose = (index) => {
        const updatedTitles = [...titles];
        updatedTitles.splice(index, 1);
        setTitles(updatedTitles);
    };

    return (
        <div className="container">
            <div className="card">

                <DragDropContext onDragEnd={onDragComplete}>
                    <Droppable droppableId="drag-drop-list">
                        {(provided, snapshot) => (
                            <div
                                className="drag-drop-list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {titles.map((item, index) => (
                                    <Draggable
                                        draggableId={`${item}-${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <Card
                                                sx={{background: '#388e3c'}}
                                                className="item-card"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                
                                            >
                                                <span className="drag-icon">
                                                    <DragIndicatorIcon sx={{width: '1.5em', height: '2em'}} color="white"/>
                                                </span>
                                                <p className="item-label">
                                               
                                                    <TextField 
                                                        className="item-input"
                                                        placeholder={`Título de la diapositiva numero ${index +1}`}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment sx={{fontWeight:'bold'}} position="start">{index + 1}.</InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <IconButton className="close-title-btn" onClick={(e) => onClose(index)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            )
                                                        }}
                                                        value={item}
                                                        onChange={(e) => {
                                                            const updatedTitles = [...titles];
                                                            updatedTitles[index] = e.target.value;
                                                            setTitles(updatedTitles);
                                                        }}
                                                    />
                                                </p>
                                            </Card>
                                        )}
                               
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Button 
                sx={{float: 'right', m:1}} 
                variant="contained" 
                color="primary" 
                onClick={(e) => addTitle()}>
                    + <RiSlideshowLine style={{marginLeft:5}} fontSize={24}/>
            </Button>
        </div>
        
    );
};

export default TitleList;