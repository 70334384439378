import './style.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "./pages/Home";
import Chat from "./pages/Chat";
import Image from "./pages/Image";
import ChatDocs from "./pages/ChatDocs";
import Slides from "./pages/Slides";
import Video from "./pages/Video";
import DataAnalisis from "./pages/DataAnalisis";

import { Signup } from "./pages/auth/Signup";
import { Login } from "./pages/auth/Login";

import Usage from "./pages/Usage";

import { AuthProvider } from './contexts/authContext'

import { PrivateRoute } from './components/PrivateRoute'

const router = createHashRouter([
  {
    path: "/",
    element: <PrivateRoute>
      <Home />
    </PrivateRoute>,
  },
  {
    path: "/chat",
    element: <PrivateRoute>
      <Chat />
    </PrivateRoute>,
  },
  {
    path: "/image",
    element: <PrivateRoute>
      <Image />
    </PrivateRoute>,
  },
  {
    path: "/slides",
    element: <PrivateRoute>
      <Slides />
    </PrivateRoute>,
  },
  {
    path: "/chatdocs",
    element: <PrivateRoute>
      <ChatDocs />
    </PrivateRoute>,
  },
  {
    path: "/dataanalisis",
    element: <PrivateRoute>
      <DataAnalisis />
    </PrivateRoute>,
  },
  {
    path: "/video",
    element: <PrivateRoute>
      <Video />
    </PrivateRoute>,
  },
  {
    path: "/usage",
    element: <Usage />,
  },
  {
    path: "/auth/signup",
    element: <Signup />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 
if (process.env.NODE_ENV !== "production") {
  import("hide-cra-error-overlay").then(({ initHideOverlay }) =>
    initHideOverlay()
  );
}