import React from 'react';
import { Typography, Grid, Link,Box } from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#388e3c',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

export const Layout = ({ children, title }) => {

    return (
        <ThemeProvider theme={darkTheme}>   

            <Grid container justify="flex-end" alignItems="center" style={{ height: '100vh' }}>
                <Grid
                    item
                    xs={0}
                    md={6}
                    lg={7}
                    style={{
                        //backgroundImage: 'url("https://www.lanacion.cl/wp-content/uploads/2022/11/71bfc6ab-soprole-scaled.jpeg")',
                        backgroundImage: 'url("/assets/auth/background.jpg")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '100%'
                    }}>

                </Grid>
                <Grid item xs={12} md={6} lg={5} sx={{ padding: '50px' }}>
                    <Typography variant="h4" style={{ textAlign: "center" }} className="my-4 font-weight-bold display-4">
                        {title}
                    </Typography>
                    <Box sx={{ margin: '0 60px' }}>
                        {children}
                    </Box>

                </Grid>
            </Grid>
        </ThemeProvider>
    )
}