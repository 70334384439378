import './ChatDocs.css'
import React, { useEffect, useState, useRef } from "react";
import Layout from '../components/base/Layout';
import axios from 'axios';
import { Toaster, toast } from 'sonner';

import {
  Grid,
} from '@mui/material'

import Tip from '../components/Tip';

import Chat from '../components/docs/Chat';
import Docs from '../components/docs/Docs';

const ChatDocs = () => {
  const [links, setLinks] = useState([])
  const [files, setFiles] = useState([])
  const [chatKey, setChatKey] = useState(null)
  const [chatHistory, setChatHistory] = useState([{ role: 'assistant', content: '👋Hola, soy un asistente virtual diseñado para consultar documentos y sitios web.' }])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false);

  function handleFileChange(event) {
    setFiles(event)
    setChatKey(Date.now())
  }

  // Validates the submission conditions
  const validateSubmission = (message, files) => {
    if (!files) {
      toast('No se han cargado documentos o videos.')
      return false;
    }

    if (message === '') {
      toast('El mensaje se encuentra vacio.')
      return false;
    }

    return true;
  };

  // Creates FormData with necessary data
  const createFormData = (files, links) => {
    const formData = new FormData();

    files.forEach(file => formData.append('documents[]', file));
    links.forEach(link => formData.append('links[]', link));

    formData.append('chatKey', chatKey);
    formData.append('question', message);
    formData.append('messages', JSON.stringify(chatHistory));


    return formData;
  };

  // Updates the chat history
  const updateChatHistory = (chatHistory, message) => {
    return [...chatHistory, { from: 'user', message }];
  };

  // Checks if the usage limit has been reached
  const checkUsageLimit = () => {
    const usageData = JSON.parse(localStorage.getItem('usageData')) || { documentQueries: 0, maxDocumentQueries: 5000 };

    if (usageData.documentQueries >= usageData.maxDocumentQueries) {
      toast('Has alcanzado el número máximo de consultas de documentos.')
      return true;
    }

    return false;
  };


  const sendDocument = async (formData, chatHistory) => {
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LANGCHAIN + '/docs', formData);
      console.log(response.data);

      // count usage data
      const usageData = JSON.parse(localStorage.getItem('usageData')) || { documentQueries: 0 };
      usageData.documentQueries += 1;
      localStorage.setItem('usageData', JSON.stringify(usageData));

      setChatHistory([...chatHistory, { from: 'assistant', message: response.data }]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateSubmission(message, files)) return;

    const formData = createFormData(files, links);
    const newChatHistory = updateChatHistory(chatHistory, message);

    /*if (checkUsageLimit()) {
      setLoading(false);
      return;
    }*/

    await sendDocument(formData, newChatHistory);

    console.log(chatHistory)

    setMessage('');
  };


  return (
    <>
      <Layout title='Consultar documentos'>
        <Toaster position="bottom-left" toastOptions={{
          style: { background: '#388e3c', color: '#fff', border: 'none' },
        }}/>

        <Tip title="Modulo de consulta de documentos con IA." subtitle='Puedes cargar documentos en <b>PDF</b>, <b>Word</b>, <b>Exel</b> enlaces de articulos, noticias y/o <b>videos de youtube</b>, una vez cargados puedes realizar consultas sobre los datos.'/>


        <form onSubmit={(event) => handleSubmit(event)}>
          <Grid container spacing={2}>
            <Grid item md="12" lg="4">
              
            <Docs 
              links={links}
              setLinks={setLinks}
              handleFileChange={handleFileChange}
              files={files}
              setFiles={setFiles} />

            </Grid>
            <Grid item lg="8">
              <Chat 
                handleSubmit={handleSubmit}
                message={message}
                setMessage={setMessage}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
                loading={loading}
                setLoading={setLoading} />
            </Grid>
          </Grid>


        </form>
      </Layout>
    </>
  );
};

export default ChatDocs;
