import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

import { BiSolidMagicWand } from 'react-icons/bi';
import { FaPaintBrush } from 'react-icons/fa';
import { IoSparklesSharp } from 'react-icons/io5';

import './Input.css';

import Step from '../../components/Step'

const Input = ({ prompt, setPrompt, generateImage, styles, selectedStyle, setSelectedStyle }) => {

    const [placeholder, setPlaceholder] = useState(
        "Gato..."
    );

    const selectStyle = (index) => {
        setSelectedStyle(index);
    }

    return (
        <>
            <Step index={1} description={'Describe lo que quieras generar.'}/>
            <TextareaAutosize
                className="image-prompt-input"
                placeholder={placeholder}
                onChange={(e) => setPrompt(e.target.value)}
                rowsMin={10}
                cols={40}
            />
            {/* <div>
                <Step index={2} description={'Estilo'}/>

                 <Grid container spacing={2}>
                    {styles.map((style, index) => (
                        <Grid item xs={3} key={style.name}>
                            <div onClick={(e) => selectStyle(index)}>
                                <img className="style-preview" src={`./assets/image/${style.name}.jpg`} alt={style.label} />
                                {style.label}
                            </div>
                        </Grid>
                    ))}
                </Grid> 
            </div> */}
            <Button variant="contained" color="primary" onClick={generateImage}>
                <span style={{ paddingRight: 10 }}>Generar imagen</span>
                <IoSparklesSharp style={{ fontSize: 25 }} />
            </Button>
        </>
    );
};

export default Input;