import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { TextField as MuiTextField, FormControl, FormHelperText } from '@material-ui/core';

import './TextField.css'

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;

  return (
    <FormControl fullWidth margin="normal">
      <MuiTextField
        {...field}
        {...props}
        label={label}
        variant="outlined"
        error={isError}
        helperText={isError ? meta.error : null}
        className="input"
      />
    </FormControl>
  );
};
