import { useState } from "react";

import { Card, CardContent, Typography, Box, Tooltip } from '@mui/material';

import Layout from '../components/base/Layout'
import Input from '../components/image/Input'
import Result from '../components/image/Result'

import Tip from '../components/Tip'

const Image = () => {
  const [imageUrl, setImageUrl] = useState(null)
  const [prompt, setPrompt] = useState("");
  const [searchPrompt, setSearchPrompt] = useState("");
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const styles = [
    { label: "foto", name: "photo", prompt: "photography" },
    { label: "ilustración", name: "ilustration", prompt: "vwctor art ilustration" },
    { label: "ilustración", name: "ilustration", prompt: "vwctor art ilustration" },
    { label: "ilustración", name: "ilustration", prompt: "vwctor art ilustration" },
  ]

  const generateImage = async () => {
    try {
      //      setLoading(true);
      setImageUrl(process.env.REACT_APP_BACKEND + '/images/generate?prompt=' + prompt)
      //setLoading(false);
      //setResult(res.data.data[0].url);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Layout title='Generar Imagen'>
      <Tip title="Modulo de generación de imagenes." subtitle='Describe lo que quieras que se genere y el sistema te devolvera una imagen' />

      <Card>
        <CardContent>
          <Input
            prompt={prompt}
            setPrompt={setPrompt}
            setSelectedStyle={setSelectedStyle}
            selectedStyle={selectedStyle}
            generateImage={generateImage}
            styles={styles} />
          <br />
          {imageUrl && <img className="result-image" src={imageUrl} alt="result" />}

          {/* <Result 
        prompt={prompt}
        result={result}
        loading={loading}
      /> */}
        </CardContent>
      </Card>



    </Layout >
  );
}

export default Image;
