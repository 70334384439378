//import './Input.css'

import React from "react";
import { TextField, Select, MenuItem, Button } from '@mui/material';
import { BiSolidMagicWand } from 'react-icons/bi'

const Input = ({ presentationPrompt, setPresentationPrompt, numSlides, setNumSlides, generateTitles }) => {

    return (
        <>

            <TextField
                fullWidth
                value={presentationPrompt}
                onChange={(e) => setPresentationPrompt(e.target.value)}
                className="slides-prompt-input"
                placeholder="Crea una presentación para ayudar a mi equipo a mejorar su performance..."
                InputProps={{
                    startAdornment: (
                    <Select
                        value={numSlides}
                        onChange={(e) => setNumSlides(e.target.value)}
                        sx={{marginTop: '12px',
                            marginBottom: '12px',
                            marginRight: '12px',
                            background: '#252525'
                        }}
                    >
        
                        {[...Array(50)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>{index + 1} Diapositivas</MenuItem>
                        ))}
                    </Select>
                    ),
                    endAdornment: (
                        <Button sx={{ ml: 2 }}  color="primary" variant="contained" onClick={generateTitles}>
                            Siguiente <BiSolidMagicWand style={{ color:'white', fontSize: 25 }} />
                        </Button>
                    )
                }}
            />

           
        </>
    );
};

export default Input;