import React from 'react';
import { Card, CardContent, Typography, LinearProgress, Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const UsageCard = ({ label, value, max, icon }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle1" component="div" gutterBottom>
    {icon} {label} {/*  <Tooltip title={'Cantidad de presentaciones'}><InfoOutlinedIcon fontSize="small" /></Tooltip> */}
    </Typography>
    <Box display="flex" alignItems="center">
      <LinearProgress 
        sx={{ flex: 1, height:'10px', borderRadius: '5px', mr: 1 }} 
        variant="determinate" 
        value={(value / max) * 100} 
      />
      <Typography variant="body2">{value} / {max}</Typography>
    </Box>
  </Box>
);

export default UsageCard;
