import { BsFillChatLeftDotsFill } from 'react-icons/bs';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { BsFillCameraVideoFill } from 'react-icons/bs'
import { HiDocumentSearch } from 'react-icons/hi'
import { AiFillHome } from 'react-icons/ai'
import { BsFillClipboardDataFill } from 'react-icons/bs'
import { AiFillPicture } from 'react-icons/ai'

const routes = [
    {
      path: "/",
      label: "Inicio",
      icon: <AiFillHome />,
    },
    {
      path: "/chat",
      label: "Chat IA",
      icon: <BsFillChatLeftDotsFill />,
      description: "Interactúa con una inteligencia artificial entrenada con informacion de tu empresa.",
    },
    {
      path: "/chatdocs",
      label: "Consultar Documentos",
      icon: <HiDocumentSearch />,
      description: "Consulta documentos usando inteligencia artificial",
    },
    {
      path: "/slides",
      label: "Crear Presentación",
      icon: <HiOutlinePresentationChartBar />,
      description: "Crea presentaciones usando inteligencia artificial.",
    },
    // {
    //   path: "/image",
    //   label: "Crear Imagenes",
    //   icon:  <AiFillPicture/>,
    //   description: "Crea imagenes usando inteligencia artificial.",
    // },
    {
      path: "/dataanalisis",
      label: "Analizar Datos",
      icon: <BsFillClipboardDataFill />,
      description: "Analizar documentos de exel usando inteligencia artificial",
      disabled: true
    },
    {
      path: "/video",
      label: "Crear Video",
      icon: <BsFillCameraVideoFill />,
      description: "Crea videos usando inteligencia artificial.",
      disabled: false
    },
  ];
  
  export default routes;