import React, { useEffect } from "react";
import Sidebar from './Sidebar'
import Navbar from './Navbar'

import { Grid, Paper, Box, Container } from '@mui/material'

import SupportChat from '../SupportChat'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#388e3c',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const Layout = ({ title, children }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    document.title = `${title} - Productiviteam`;
  }, []);

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <Navbar title={title} open={open} setOpen={setOpen} />

        <Grid container>

        <Grid item sm="4" md="4" lg="2">
            <Sidebar open={open} setOpen={setOpen} />
          </Grid>

          <Grid item sm="8" md="8" lg="10">
            <Container maxWidth="xl">
              <Box component="main" sx={{ my: 8}}>
                <Paper
                  elevation={3}
                  sx={{ p: 2, minHeight: 'calc(100vh - 100px)' }}>
                  {children}
                </Paper>
              </Box>
            </Container>

          </Grid>
        </Grid>
        <SupportChat />
      </ThemeProvider>
    </>
  );
};

export default Layout;
