import axios from 'axios';
import React, { useState } from "react";
import Layout from '../components/base/Layout';
import DropFile from '../components/DropFile'

import { Container, Grid, TextField, Button, Typography, Card, CardContent } from '@mui/material';
import { FileUpload } from '@mui/icons-material'

const Plot = ({ id }) => {
    return <>
        <img
            style={{ width: '100%', borderRadius: '20px' }}
            src={process.env.REACT_APP_BACKEND_LANGCHAIN + '/plots/' + id + '.png'} />

        <Button variant="contained" onClick={() => window.open(process.env.REACT_APP_BACKEND_LANGCHAIN + '/plots/' + id + '.png', '_blank')}>
            Descargar imagen
        </Button>
    </>
}

const DataAnalisis = () => {
    const [prompt, setPrompt] = useState('')
    const [files, setFiles] = useState([])
    const [plots, setPlots] = useState(null)

    const handleChange = (event) => {
        console.log(event)
        setFiles(event)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!files) {
            console.error('No documents selected');
            // TODO: mostrar mensaje de error en pantalla
            return;
        }

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('documents[]', files[i]);
        }

        formData.append('prompt', prompt);

        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_LANGCHAIN + '/plot', formData);
            console.log(response.data);

            setPlots(response.data)

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Layout title='Analizar Datos'>
                <Container>

                    <DropFile
                        handleChange={handleChange}
                        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    />

                    <Typography>Realizar consulta</Typography>
                    <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        onSubmit={(e) => { e.preventDefault(); }}
                        value={prompt}
                        placeholder="Escribe una consulta"
                        onChange={(e) => setPrompt(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <Button onClick={(e) => handleSubmit(e)} variant="contained">
                                    Analizar <FileUpload />
                                </Button>
                            )
                        }}
                    />

                    {plots && plots.map((id) => (
                        <Plot key={id} id={id} />
                    ))}

                </Container>

            </Layout>
        </>
    );
};

export default DataAnalisis;
