import React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton
} from '@mui/material';

import { Delete, Lightbulb, YouTube, Web } from '@mui/icons-material';
import { FaFilePdf, FaFileAlt, FaFileWord } from 'react-icons/fa';

const ItemIcon = ({ item, type }) => {
    console.log(item)
    if (type === 'link') {
        const isYouTubeLink = item.includes("youtube.com");
        return isYouTubeLink ? <YouTube /> : <Web />;
    } else if (type === 'file') {
        const isPdfFile = item.includes(".pdf");
        const isTxtFile = item.includes(".txt");
        const isDocxFile = item.includes(".docx");

        if (isPdfFile) {
            return <FaFilePdf />;
        } else if (isTxtFile) {
            return <FaFileAlt />;
        } else if (isDocxFile) {
            return <FaFileWord />;
        } else {
            return <FaFileAlt />; // Placeholder para otros tipos de archivos, cambia según tu lógica
        }
    }
    // Retorna null o un icono por defecto si no se cumple ninguna condición
    return null;
};


const ItemList = ({ items, setItems, type }) => {
    const handleDeleteItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    if ((!items || items.length === 0 ) && type == 'link') return (
        <Box sx={{ width: '100%', border: 'solid 1px #525252' }}>
            <Typography sx={{ textAlign: 'center', padding: '15px', color: '#898989' }}>
                <p> No hay {type === 'link' ? 'enlaces' : 'archivos'} aún</p>
                <p><Lightbulb /> Puedes cargar {type === 'link' ? 'enlaces de artículos, videos de YouTube, etc.' : 'archivos'}</p>
            </Typography>
        </Box>
    );

    return (
        <List>
            {items.map((item, index) => (
                <ListItem key={index} style={{ background: '#1a1a1a', borderRadius: '8px', marginBottom: '10px' }}>
                    <ListItemIcon>
                        <ItemIcon item={item} type={type} />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                    <IconButton onClick={() => handleDeleteItem(index)} color="secondary" aria-label="delete">
                        <Delete />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    );
}

export default ItemList;
