import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, Button, TextField, IconButton, InputLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TemplateCard from './TemplateCard';

import { supabase } from '../../lib/supabase'

const TemplateSelector = ({ selectedTemplate, setSelectedTemplate }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newImage, setNewImage] = useState('');
  const [newTemplateName, setNewTemplateName] = useState(''); // Nuevo estado para el nombre
  const [templateList, setTemplateList] = useState();

  async function getSlides() {

    const { data: slides, error } = await supabase
      .from('slide_templates')
      .select('*')

    setTemplateList(slides)
    console.log(slides)
  }

  useEffect(() => {
    getSlides()
  }, [])


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
  };

  const handleAddImage = async () => {
    if (newImage) {

      const { data: insertedTemplate, error: insertError } = await supabase
        .from('slide_templates')
        .insert({ name: newTemplateName })
        .select()
        .single()
        .limit(1)

      const { data: uploadedImage, error: uploadError } = await supabase.storage
        .from('slide_templates')
        .upload(`${insertedTemplate.id}.png`, newImage);

      setTemplateList([...templateList, insertedTemplate]);

      setNewImage('');
      setNewTemplateName('');
      handleCloseModal();
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        {templateList && templateList.map((template, index) => (
          <Grid item xs={3} key={index}>
            <TemplateCard
              template={template}
              index={index}
              setSelectedTemplate={setSelectedTemplate}
              selected={selectedTemplate === template.id}
            />
          </Grid>
        ))}
        <Grid item xs={3} sx={{display: 'grid'}}>
          <IconButton color="primary" onClick={handleOpenModal}>
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Cargar plantilla</DialogTitle>
        <DialogContent>
          <InputLabel>Nombre de la plantilla</InputLabel>
          <TextField
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
          <InputLabel>Selecciona una imagen</InputLabel>
          <TextField
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddImage}
          >
            Agregar
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TemplateSelector;
