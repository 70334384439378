import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ isSelected }) => ({
  maxWidth: 345,
  cursor: 'pointer',
  transition: 'transform 0.2s',
  border: isSelected ? '1px dashed white' : '1px dashed transparent',
}));

const TemplateCard = ({ selected, index, setSelectedTemplate, template }) => {

  const handleCardClick = (id) => {
    setSelectedTemplate(id);
  };

  return (
    <StyledCard isSelected={selected} onClick={() => handleCardClick(template.id)}>
      <ButtonBase
        style={{
          display: 'block',
          width: '100%',
        }}
      >
        <CardMedia
          component="img"
          height="170"
          image={`https://oshedpjatcqwywauqkhs.supabase.co/storage/v1/object/public/slide_templates/${template.id}.png`}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
        <CardContent>
          <Typography variant="h6" component="div">
            {template.name}
          </Typography>
        </CardContent>
      </ButtonBase>
    </StyledCard>
  );
};

export default TemplateCard;
