import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import * as Yup from 'yup';

import { Button, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase'

import { Layout } from './Layout';

export const Login = () => {
  const [loginError, setLoginError] = useState(null); // Nuevo estado para el error de inicio de sesión

  const navigate = useNavigate();

  const validate = Yup.object({
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('El correo electrónico es obligatorio'),
    password: Yup.string()
      .min(6, 'La contraseña debe tener al menos 6 caracteres')
      .required('La contraseña es obligatoria'),
  });

  const handleSubmit = async (values) => {
    try {
      console.log(supabase.auth)
      const { data, error } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      if (error) {
        console.log(error);

        if (error.message === 'Invalid login credentials') {
          setLoginError('Credenciales inválidas. Verifica tu correo y contraseña.');
        } else {
          setLoginError(error.message);
        }
      } else {
        // Login successful, you can redirect the user or perform other actions.
        navigate('/'); // Redirect to the dashboard page, for example.
      }
    } catch (error) {
      console.error(error.message);
      // Handle other errors, if any.
    }
  };

  return (
    <>
      <Layout title={'Iniciar sesión'}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {formik => (
            <>
              <Typography variant="subtitle1" style={{ color: 'grey', textAlign: 'center' }}>
                ¿No tienes cuenta? <Link onClick={() => navigate('/auth/signup')}>Registrarse</Link>
              </Typography>

              <form onSubmit={formik.handleSubmit}>
                <TextField placeholder="Correo electrónico" name="email" type="email" fullWidth />
                <TextField placeholder="Contraseña" name="password" type="password" fullWidth />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="medium"
                  style={{
                    marginTop: '20px',
                    width: '100%',
                    borderRadius: '28px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Iniciar sesión
                </Button>

                {loginError && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {loginError}
                  </div>
                )}
              </form>
            </>
          )}
        </Formik>
      </Layout>
    </>
  );
};
