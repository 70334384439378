import { useState, useEffect } from "react";
import { Card, CardContent, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HiOutlinePresentationChartBar, HiDocumentSearch } from 'react-icons/hi';
import { BsFillClipboardDataFill } from 'react-icons/bs';
import Layout from "../components/base/Layout";
import UsageCard from "../components/Usage";

const Usage = () => {
  const defaultUsage = {
    presentations: 0,
    maxPresentations: 20,
    documentQueries: 0,
    maxDocumentQueries: 20,
    dataAnalysis: 0,
    maxDataAnalysis: 20,
    renewalDate: '',
  };

  const [usage, setUsage] = useState(() => {
    // Retrieve the usage data from local storage or use default
    const savedUsage = localStorage.getItem('usageData');
    return savedUsage ? JSON.parse(savedUsage) : defaultUsage;
  });

  useEffect(() => {
    // Save the current usage to local storage whenever it changes
    localStorage.setItem('usageData', JSON.stringify(usage));
  }, [usage]);

  useEffect(() => {
    // Calculate the start of the next month for renewal date
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedRenewalDate = nextMonth.toLocaleDateString('es-CL', options);

    setUsage(prevUsage => ({ ...prevUsage, renewalDate: formattedRenewalDate }));
  }, []);

  return (
    <Layout title='Creditos'>
      <Card sx={{ minWidth: 275, borderRadius: '16px', boxShadow: '0px 4px 12px rgba(0,0,0,0.1)', m: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', mb: 1.5 }} color="text.secondary">
            Tu uso 
          </Typography>
          
          <UsageCard icon={<HiOutlinePresentationChartBar />} label="Presentaciones" value={usage.presentations} max={usage.maxPresentations} />
          <UsageCard icon={<HiDocumentSearch />} label="Consultas de documentos" value={usage.documentQueries} max={usage.maxDocumentQueries} />
          {/* <UsageCard icon={<BsFillClipboardDataFill />} label="Análisis de datos" value={usage.dataAnalysis} max={usage.maxDataAnalysis} /> */}

          <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
            Tu plan se renovara el {usage.renewalDate}
          </Typography>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default Usage;
