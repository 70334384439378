import React from "react";

import { styled } from '@mui/material/styles';

import { useNavigate, useLocation } from 'react-router-dom'

import { ChevronLeft } from '@mui/icons-material';

import { MdFeedback } from 'react-icons/md';
import { FaMoneyBillWave } from "react-icons/fa";

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  IconButton,
  List,
  Divider,
  Hidden
} from '@mui/material'

import routes from '../../routes';

const DrawerHeader = styled('div')(({ }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const DrawerContainer = ({ open, setOpen, children }) => {
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return <Drawer
    sx={{
      width: 300,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: 300,
        boxSizing: 'border-box',
      },
    }}
    variant="persistent"
    anchor="left"
    open={open}
  >
    <DrawerHeader>
      <IconButton onClick={toggleDrawer}>
        <ChevronLeft />
      </IconButton>
    </DrawerHeader>

    {children}

  </Drawer>
}

function SidebarItem({ route }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname == path;
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <ListItemButton
      sx={{
        borderRadius: '14px',
        mb: 1,
        color: isActive(route.path) ? '#5eff5e' : 'white'
      }}
      onClick={() => handleNavigate(route.path)}
      selected={isActive(route.path)}
      disabled={route.disabled}
    >
      <ListItemIcon sx={{color: isActive(route.path) ? '#5eff5e' : 'gray', 'fontSize': '30px', }}>{route.icon}</ListItemIcon>
      <ListItemText primary={route.label} />
    </ListItemButton>
  );
}

const SupportItem = () => {

  return <ListItemButton
    sx={{'borderRadius': '14px'}}
    onClick={(e) => window.open('https://forms.gle/Qv4HHuNYJEx2bcPQ9', '__blank')}
  >
    <ListItemIcon sx={{ 'fontSize': '30px', color: 'gray' }}>
      <MdFeedback />
    </ListItemIcon>
    <ListItemText primary={'Comentarios'} />
  </ListItemButton>
}

const UsageItem = () => {

  const navigate = useNavigate();


  const handleNavigate = (path) => {
    navigate(path);
  };

  return <ListItemButton
    sx={{'borderRadius': '14px'}}
    onClick={() => handleNavigate('/usage')}
    >
    <ListItemIcon sx={{ 'fontSize': '30px', color: 'gray' }}>
      <FaMoneyBillWave/>
    </ListItemIcon>
    <ListItemText primary={'Mis creditos'} />
  </ListItemButton>
}

const ItemList = () => {
  return (<List component="nav">
    {routes.map((route, key) => (
      <SidebarItem route={route}/>
    ))}
    <Divider sx={{ my: 1 }} />
    {/*<UsageItem/>*/}
    <SupportItem />
  </List>)
}

const Sidebar = ({ open, setOpen }) => {
  return (
    <>
      <Hidden smUp>
        <DrawerContainer
          open={open}
          setOpen={setOpen}>
          <ItemList />
        </DrawerContainer>
      </Hidden>

      <Hidden smDown>
        <Box mt={8}>
          <ItemList />
        </Box>
      </Hidden>
    </>
  );
};

export default Sidebar;
