import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/base/Layout';
import { Grid, Card, CardContent, Typography, ButtonBase } from '@mui/material';
import styled from 'styled-components';
import routes from '../routes';
import Tip from '../components/Tip'

const StyledCard = styled(Card)`
  transition: background-color 0.2s;
  min-height: 250px;
  border-radius: 20px!important;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const StyledCardContent = styled(CardContent)`
  text-align: center;
`;

const Subtitle = styled(Typography)`
  color: gray;
`;

const Home = () => {
  const navigate = useNavigate(); // Get the navigate function

  const filteredRoutes = routes.filter(route => route.path !== '/');

  const handleCardClick = route => {
    if (!route.disabled) {
        // Your navigation logic here
      navigate(route.path);
    }

  };

  return (
    <Layout title='Inicio'>

      <Tip title="Bienvenido a Produciviteam" subtitle='' />


      <Grid container spacing={2}>
        {filteredRoutes.map((route, index) => (
          
          !route.disabled && <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
            <StyledCard component={ButtonBase} onClick={() => handleCardClick(route)}>
              <StyledCardContent>
                <div style={{ marginBottom: '8px', fontSize: '50px' }}>
                  {route.icon}
                </div>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginBottom: '8px',
                  }}
                  variant="subtitle1"
                >
                  {route.label}
                </Typography>
                <Subtitle variant="body2">{route.description}</Subtitle>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Home;