import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Modal, Backdrop, Fade, Typography, Button } from '@mui/material';
import { BsFillImageFill } from "react-icons/bs";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AssetThumbnail = ({ clip, handleVideoChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0); 
  const [file, setFile] = useState(null);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleChooseImage = (index) => {
    setSelectedImage(index);
    handleVideoChange(index);
  };

  const onDrop = useCallback(acceptedFiles => {
    // Only take the first file and generate its preview
    const file = acceptedFiles[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      // Create a new object for the file, including the preview URL and a custom property to indicate if it's an image or video
      const fileWithPreview = {
        file: file,
        preview: preview,
        isVideo: file.type.startsWith('video/')
      };
      setFile(fileWithPreview); // Update the state to hold this new object

      handleVideoChange(fileWithPreview);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false }); // Ensure only one file can be dropped

  // Adjusted to conditionally render image or video based on the file type
  const thumb = file && (
    <div style={{ display: 'inline-flex', borderRadius: '2px', border: 'none', marginBottom: '8px', marginRight: '8px', width: '300px', height: '150px', padding: '4px', boxSizing: 'border-box' }} key={file.name}>
      <div style={{ display: 'flex', minWidth: '0', overflow: 'hidden' }}>
        {file.type === 'image' ? (
          <img
            src={file.preview}
            style={{ display: 'block', width: 'auto', height: '100%' }}
            alt={file.name}
            onLoad={() => { URL.revokeObjectURL(file.preview); }}
          />
        ) : (
          <video
            style={{ display: 'block', width: 'auto', height: '100%' }}
            controls
            src={file.preview}
            onLoadedMetadata={() => { URL.revokeObjectURL(file.preview); }}
          ></video>
        )}
      </div>
    </div>
  );


  if (clip?.videos?.length === 0) return <></>;

  return (
    <Box width="100%" borderRadius="4px" overflow="hidden">

      {file ? (
        file.type === 'image' ? (
          <img
            src={file.preview}
            style={{
              width: '64px',
              height: '64px',
              objectFit: 'cover',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            alt="Miniatura"
            onClick={handleOpenModal}
          />
        ) : (
          <video
            style={{
              width: '64px',
              height: '64px',
              objectFit: 'cover',
              cursor: 'pointer',
              borderRadius: '10px',
            }}
            controls={false}
            src={file.preview}
            onClick={handleOpenModal}
          ></video>
        )
      ) : (
        <img
          src={clip.videos[selectedImage]?.image}
          style={{
            width: '64px',
            height: '64px',
            objectFit: 'cover',
            cursor: 'pointer',
            borderRadius: '10px',
          }}
          alt="Miniatura"
          onClick={handleOpenModal}
        />
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <BsFillImageFill /> Elige una imagen de la galería
            </Typography>
            <Grid container spacing={2}>
              {clip.videos.map((video, index) => (
                <Grid item key={index} lg={3} md={4} sm={6} xs={12}>
                  <img
                    src={video.image}
                    alt={`Thumbnail ${index}`}
                    style={{
                      width: '120px',
                      height: '120px',
                      objectFit: 'cover',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      border: selectedImage === index ? '2px solid #4CAF50' : '1px solid #ddd',
                    }}
                    onClick={() => handleChooseImage(index)}
                  />
                </Grid>
              ))}
            </Grid>
            {/* <div {...getRootProps({ className: 'dropzone', style: { marginTop: '16px', padding: '20px', border: '2px dashed #ccc', borderRadius: '8px' } })}>
              <input {...getInputProps({ accept: 'image/*,video/*' })} />
              <p>Arrastra aquí una imagen o video, o haz clic para seleccionar archivos</p>
            </div>
            <aside style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '16px' }}>
              {thumb}
            </aside>
            <Button sx={{ width: '100%', mt: 3, py: 1 }} variant="contained" color="primary" onClick={handleCloseModal}>
              Elegir
            </Button> */}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AssetThumbnail;
